import LineBreak from '../components/lineBreak';

const experiences: {
  title: string;
  company: string;
  date: string;
  location: string;
  description: string[];
}[] = [
  {
    title: 'Software Engineer',
    company: 'Heartbeat Medical',
    date: 'Jan 2023 – Present',
    location: 'Berlin, DE',
    description: [
      "Contributing to the backend team's effort on the core functionality for the new product; aiming to empower both clinicians and patients.",
      'Led the development of a feature enabling healthcare providers to generate a secure 4-digit code, allowing patients to complete digital questionnaires on a tablet during their hospital visit.',
      'Played a key role in developing a custom medical package to enhance clinician and patient experience, ensuring seamless operations for both stakeholders.',
      'Enhanced patient self-signup process to streamline onboarding and improve user experience, achieving sub-90-second completion times and driving significant monthly signup growth.',
      'Developed a customization service for product configurations, enhancing clinician onboarding efficiency. This system allows the input of numerous questions (currently 1000+), tailored across various questionnaires.',
    ],
  },
  {
    title: 'Backend Software Engineer',
    company: 'Retailo Technologies',
    date: 'Oct 2021 – Nov 2022',
    location: 'Islamabad, PK',
    description: [
      'Led the effort of a feature to divide the warehouse into multiple logical locations, which the product team estimated would improve inventory accuracy by up to 95% or more.',
      'Part of the backend team that created a warehouse management system to keep track of inventory which increased the accuracy of physical stock from 40% to 70%.',
      'Revamped existing logistic flows to decrease delivery and loading time which led to an additional order delivery per batch increasing 10% sales per route.',
      "Delivered a feature that enabled 5,000 SKUs available on the app which contributed an estimated 20% incremental GMV to the companies' products portfolio.",
      'Restructured the calculation library which escalated the accuracy of the monetary transactions and removed the precision error to near',
    ],
  },
  {
    title: 'Software Engineer',
    company: 'Public R&D Organization',
    date: 'July 2019 - Sept 2021',
    location: 'Islamabad, PK',
    description: [
      'Developed a TA/DA system for the timely and accurate generation of TA/DA. Improved time of a single TA/DA creation from ~15 minutes to ~2 minutes.',
      'Engineered new stock flows in the system that reduced the occurrence of error from 20% to 5%, allowing the creation of critical stock documents with more accuracy in less time.',
      'Initiated an effort to fix bugs to optimize the performance of the Letter Distribution Portal. Daily distribution of letters grew from 30 to 100 giving a drastic increase in the overall performance.',
    ],
  },
];

function About() {
  return (
    <div className='section' id='about'>
      <div className='box flex-col'>
        <h3 className='underline-with-primary'>Summary</h3>
        <p>
          A backend engineer with 5 years of experience in software design and development,
          contributing to backend infrastructure in the digital health sector.
        </p>
      </div>
      <LineBreak />
      <div className='flex-col box'>
        <h3 className='underline-with-primary'>Experience</h3>
        {experiences.map((experience) => (
          <>
            <div>
              <div className='flex space-between'>
                <div className='flex-col'>
                  <h3>{experience.title}</h3>
                  <h4>{experience.company}</h4>
                </div>

                <div className='flex-col'>
                  <h4>{experience.date}</h4>
                  <h4>{experience.location}</h4>
                </div>
              </div>
              <ul>
                {experience.description.map((desc, index) => (
                  <li key={index}>{desc}</li>
                ))}
              </ul>
            </div>
          </>
        ))}
      </div>
      <LineBreak />
      <div className='flex-col box'>
        <h3 className='underline-with-primary'>Technical Skills</h3>
        <div>
          <strong>Languages: </strong>JavaScript, TypeScript, SQL with working knowledge of Go and
          C++
        </div>
        <div>
          <strong>Frameworks: </strong>Node.js, NestJS, ExpressJS, ReactJS, Remix
        </div>
        <div>
          <strong>Tools/Technologies: </strong>CI/CD, Docker/Docker Compose, Prisma, GitHub actions,
          Redis, AWS( EC2, ECS, CloudFormation, Cloudwatch, SNS and SQS), Terraform, Consul,
          Testing(Jest, Vitest, Playwright), Microservices
        </div>
      </div>
      <LineBreak />
      <div className='flex-col box'>
        <h3 className='underline-with-primary'>Education</h3>
        <div className='flex space-between'>
          <div>
            <h4>FAST-National University of Computer and Emerging Sciences, Islamabad</h4>
            <span>Bachelors of Science in Computer Science</span>
          </div>
          <h4 className='center'>2015 - 2019</h4>
        </div>
      </div>
    </div>
  );
}

export default About;
