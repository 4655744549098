import React from 'react';

function Home() {
  return (
    <div className='section center flex-col w-full' style={{minHeight: 'calc(100vh - 100px)'}}>
      <div className='flex-col box'>
        <p>Hi, I am Osama Rehman, a</p>
        <h1 className='typewriter center'>Software Engineer</h1>
        <p>based in Berlin, Germany</p>
      </div>
      <div className='flex box w-full center'>
        <a
          className='btn btn-primary'
          href='#about'
        >
          Resume
        </a>
        <a className='btn btn-secondary' href='mailto:osamarehman1@outlook.com'>
          Contact
        </a>
        <a
          className='btn btn-linkedin'
          href='https://www.linkedin.com/in/osaama01/'
          target='_blank'
          rel='noreferrer'
        >
          LinkedIn
        </a>
      </div>
    </div>
  );
}

export default Home;
