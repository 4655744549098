const Header = () => (
  <div className='header'>
      <svg width='200' height='40' viewBox='0 0 200 40' xmlns='http://www.w3.org/2000/svg'>
        <text
          x='0'
          y='30'
          font-family='Arial, sans-serif'
          font-size='32'
          font-weight='bold'
          fill='#F3A948'
        >
          saamo tech
        </text>
      </svg>
    <div className='header-items center'>
      <a href='#home'>
        Home
      </a>
      <a href='#about'>Resume</a>
    </div>
  </div>
);

export default Header;
