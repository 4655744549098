import Footer from '../components/footer';
import Header from '../components/header';
import About from './About';
import Home from './Home';

const Index = () => {
  return (
    <div className='app w-full flex-col'>
      <Header />
      <Home />
      <About />
      <Footer />
    </div>
  );
};

export default Index;